import React from "react"

function fetchReducer(_state, { type, response }) {
  switch (type) {
    case "fetching": {
      return { error: false, response: null, pending: true, success: false }
    }
    case "success": {
      return { error: false, response, pending: false, success: true }
    }
    case "error": {
      return { error: true, response, pending: false, success: false }
    }
    default:
      throw new Error(`Unsupported type: ${type}`)
  }
}

export function useFetch({ url }) {
  const [state, dispatch] = React.useReducer(fetchReducer, {
    error: null,
    response: null,
    pending: false,
    success: false,
  })

  const refetch = React.useCallback(
    body => {
      dispatch({ type: "fetching" })
      return fetch(url, {
        method: "post",
        body: body ? JSON.stringify(body) : undefined,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(r => r.json())
        .then(
          response =>
            dispatch({ type: response.error ? "error" : "success", response }),
          error => dispatch({ type: "error", error })
        )
    },
    [url]
  )

  return { ...state, refetch }
}
